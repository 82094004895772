import React, { Component } from 'react';
import { Button, Label, } from "reactstrap";

// import images
import "flatpickr/dist/themes/material_blue.css"
import "../../Pages/css/_authentication.css"
import Select from "react-select";

import "../Authorization/login.css"
import { withNamespaces } from 'react-i18next';

import 'toastr/build/toastr.min.css'
import i18n from '../../i18n';
import Loader from './Loader';

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { BASEURL, getcountrycode } from '../../Global';

class PreferencesNotification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedLanguage: "",
            reminderTime: "",
            selectedTithiReminder: "",
            selectedPanchKalyanak: "",
            selectedExtraNotes: "",
            selectedJainFestival: "",
            latitude: "",
            longitude: "",
            country_code: "",
            userData: [],
            showLoader: false
        }
        this.interval = null;
    }

    async componentDidMount() {
        this.getuser()
        const Language = await localStorage.getItem('selectedLanguage')
        if (Language) {
            this.setState({ selectedLanguage: Language })
        } else {
            this.setState({ selectedLanguage: "English" })
        }
        // this.interval = setInterval(this.myBackgroundFunction, 30000); // 30,000 milliseconds = 30 seconds
    }

    getLoaction() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                this.handleSuccess,
                this.handleError
            );
        } else {
            toastr.error("Geolocation is not supported by your browser")
            this.setState({ error: 'Geolocation is not supported by your browser' });
        }
    }

    handleSuccess = async (position) => {
        if (position) {
            const con = await getcountrycode()
            // localStorage.setItem("saralLongitude", position.coords.longitude)
            // localStorage.setItem("saralLatitude", position.coords.latitude)
            // localStorage.setItem("saralCountry", con.countrycode)
            this.setState({
                latitude: position.coords?.latitude,
                longitude: position.coords?.longitude,
                country_code: con.countrycode
            });
        } else {
            // console.log("Not 2")
        }
    };

    handleError = () => {
        toastr.error("Please Allow Location ..")
    }

    changeLanguageAction = async (lng) => {
        if (lng === "English") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'English')
            await i18n.changeLanguage('eng');
        } else if (lng === "Gujarati") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'Gujarati')
            await i18n.changeLanguage('guj');
        } else if (lng === "Hindi") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'Hindi')
            await i18n.changeLanguage('hin');
        } else {
            await this.setState({ selectedLanguage: 'eng', })
            await localStorage.setItem('selectedLanguage', 'English')
            await i18n.changeLanguage('eng');
        }
    }

    async getuser() {
        this.setState({ showLoader: true })
        const Token = await localStorage.getItem("authToken")
        const userID = await localStorage.getItem("authUser_ID")

        try {
            await fetch(`${BASEURL}/getusersettingsbyuserid`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    response.json().then(async (responseJson) => {
                        if (responseJson.data) {
                            // console.log("Respionse JSON", responseJson);

                            this.setState({
                               selectedTithiReminder: responseJson.data.tithi_reminder,
                               reminderTime: responseJson.data.reminder_time,
                               selectedPanchKalyanak: responseJson.data.panch_kalyanak,
                               selectedExtraNotes: responseJson.data.extra_notes,
                               selectedJainFestival: responseJson.data.jain_festival,
                               showLoader: false
                            })
                            // console.log("console log data", this.state.userData)
                            // await this.changeLanguageAction(responseJson.data.language)
                        } else {
                            this.setState({ showLoader: false })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    async userProfile() {
        const Token = await localStorage.getItem("authToken")
        const userID = await localStorage.getItem("authUser_ID")
        this.setState({ showLoader: true })
        try {
            await fetch(`${BASEURL}/updateusersettingsbyuserid`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "tithi_reminder": this.state.selectedTithiReminder,
                        "reminder_time": this.state.reminderTime,
                        "panch_kalyanak": this.state.selectedPanchKalyanak,
                        "extra_notes": this.state.selectedExtraNotes,
                        "jain_festival": this.state.selectedJainFestival,
                         "type": "web", 
                    })
                })
                .then((response) => {
                    response.json().then(async (responseJson) => {
                        // console.log("Respionse JSON", responseJson);
                        if (responseJson.message == "An Exception occurred while trying to authenticate the User.") {
                            // this.props.history.push(process.env.PUBLIC_URL + '/login')
                            localStorage.clear()
                            window.location.href = process.env.PUBLIC_URL + "/login"
                            return false;
                        }
                        if (responseJson.data) {
                            toastr.success("User Notification Updated Succesfully")
                            // this.getuser()
                            this.setState({ showLoader: false })
                        } else {
                            toastr.warning("Something Went Wrong !!")
                            this.setState({ showLoader: false })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    myBackgroundFunction = async () => {
        const Language = await localStorage.getItem('selectedLanguage')
        if (Language) {
            this.setState({ selectedLanguage: Language })
        } else {
            this.setState({ selectedLanguage: "English" })
        }
    }

    render() {

        const options = [
            { label: "Select Option", value: "" },
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
        ];

        const reminderTimeList = [
            { label: "Select Option", value: "" },
            { label: "On Tithi Day Morning", value: "today_morning" },
            { label: "Day before Tithi Morning", value: "yesterday_morning" },
            { label: "Day before Tithi Evening", value: "yesterday_evening" },
        ];

        return (
            <React.Fragment>
                <Loader isLoading={this.state.showLoader} />
                <div className='ast_toppadder70 ast_bottompadder20 custom-bg-login'>
                    <div className="ast_toppadder70 ast_bottompadder70">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12 col-12 mt-3">
                                    <div className="mb-3">
                                        <Label className="form-label"> Tithi Reminder</Label>
                                        <Select
                                            value={options.find(option => option.value === this.state.selectedTithiReminder)}
                                            onChange={(e) => this.setState({ selectedTithiReminder: e.value })}
                                            options={options}
                                            classNamePrefix="select2-selection"
                                            placeholder='Select Option'
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 col-12 mt-3">
                                    <div className="mb-3">
                                        <Label className="form-label"> Reminder Time</Label>
                                        <Select
                                            value={reminderTimeList.find(option => option.value === this.state.reminderTime)}
                                            onChange={(e) => this.setState({ reminderTime: e.value })}
                                            options={reminderTimeList}
                                            classNamePrefix="select2-selection"
                                            placeholder='Select Option'
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                    <div className="mb-3">
                                        <Label className="form-label"> Extra Notes</Label>
                                        <Select
                                            value={options.find(option => option.value === this.state.selectedExtraNotes)}
                                            onChange={(e) => this.setState({ selectedExtraNotes: e.value })}
                                            options={options}
                                            classNamePrefix="select2-selection"
                                            placeholder='Select Option'
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                    <div className="mb-3">
                                        <Label className="form-label"> Jain Festival</Label>
                                        <Select
                                            value={options.find(option => option.value === this.state.selectedJainFestival)}
                                            onChange={(e) => this.setState({ selectedJainFestival: e.value })}
                                            options={options}
                                            classNamePrefix="select2-selection"
                                            placeholder='Select Option'
                                        />
                                    </div>
                                </div>
                                   <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                    <div className="mb-3">
                                        <Label className="form-label"> Panch Kalyanak</Label>
                                        <Select
                                            value={options.find(option => option.value === this.state.selectedPanchKalyanak)}
                                            onChange={(e) => this.setState({ selectedPanchKalyanak: e.value })}
                                            options={options}
                                            classNamePrefix="select2-selection"
                                            placeholder='Select Option'
                                        />
                                    </div>
                                </div>
                              
                                <div style={{ textAlign: 'end', }}>
                                    <a style={{ width: "25%" }} onClick={() => this.userProfile()} className="ast_btn mt-2">Submit</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(PreferencesNotification);