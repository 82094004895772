

import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { BASEURL, logobase64 } from '../../Global';
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import logo from '../../Assets/images/Logo/logo.png';
import logoFirstPage from '../../Assets/images/Logo/saral-pdf-first-page.png';
import { withNamespaces } from "react-i18next";
import Select from "react-select";
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { AvCheckbox, AvCheckboxGroup, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import i18n from '../../i18n';
import Loader from "../Common/Loader";

const date = new Date();
const monthName = date.getMonth() + 1;
const yearName = date.getFullYear();
const dateName = date.getDate();
const GenerateCalendarPDF = (props) => {
    const calendarRef = useRef(null);
    const [data, setData] = useState([])
    const [pachkhanData, setpachkhanData] = useState([])
    const [pachkhanDataEvening, setpachkhanDataEvening] = useState([])
    const [yearData, setyearData] = useState([])
    const [selectedEnglishYear, setSelectedEnglishYear] = useState(yearName)
    const [selectedHinduYear, setSelectedHinduYear] = useState(yearName)
    const [yearHindiData, setyearHindiData] = useState([])
    const [CalendarType, setCalendarType] = useState("Gregorian")
    const [languageType, setLanguageType] = useState("")
    const [getTithi, setGetTithi] = useState("")
    const [themeSettingData, setThemeSettingData] = useState([])
    const [htmlContent, setHtmlContent] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('English');

    useEffect(() => {
        getuser()
        getThemeSetting();
        getPachkhan();
        getPachkhanEvening();
        getYear();
        getHinduYear();
        changeLanguageAction(selectedLanguage)
    }, [selectedLanguage])
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const changeLanguageAction = async (lng) => {
        let language = "eng"; // default
        if (lng === "Gujarati") {
            language = "guj";
        } else if (lng === "Hindi") {
            language = "hin";
        }
        await i18n.changeLanguage(language);
    };
    const getPachkhan = async (type = "morning") => {
        const Token = await localStorage.getItem("authToken")

        try {
            await fetch(`${BASEURL}/getfrontpanchakhan`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "type": type,
                    })
                })
                .then((response) => {
                    response.json().then((responseJson) => {
                        // console.log("Respionse JSON", responseJson);
                        if (responseJson.message == "An Exception occurred while trying to authenticate the User.") {
                            localStorage.clear()
                            window.location.href = process.env.PUBLIC_URL + '/login';
                        }
                        if (responseJson.data) {
                            setpachkhanData(responseJson.data)
                        } else {
                            toastr.warning("Something Went Wrong !!")
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    const getPachkhanEvening = async (type = "evening") => {
        const Token = await localStorage.getItem("authToken")

        try {
            await fetch(`${BASEURL}/getfrontpanchakhan`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "type": type,
                    })
                })
                .then((response) => {
                    response.json().then((responseJson) => {
                        // console.log("Respionse JSON", responseJson);
                        if (responseJson.message == "An Exception occurred while trying to authenticate the User.") {
                            localStorage.clear()
                            window.location.href = process.env.PUBLIC_URL + '/login';
                        }
                        if (responseJson.data) {
                            setpachkhanDataEvening(responseJson.data)
                        } else {
                            toastr.warning("Something Went Wrong !!")
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    const getThemeSetting = async () => {
        try {
            await fetch(`${BASEURL}/findthemesettingswithoutauth/NxOpZowo9GmjKqdR`,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    response.json().then((responseJson) => {
                        if (responseJson) {
                            if (responseJson) {
                                setThemeSettingData(responseJson.data)
                                // console.log("Theme Created", responseJson)
                            }
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    const getuser = async () => {
        const Token = await localStorage.getItem("authToken")
        const userID = await localStorage.getItem("authUser_ID")

        try {
            await fetch(`${BASEURL}/findtenantusersbyid/${userID}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    response.json().then(async (responseJson) => {
                        if (responseJson.data) {
                            // this.setState({
                            //     userData: responseJson.data
                            //     , selectedLanguage: responseJson.data.language
                            //     , latitude: responseJson.data.latitude
                            //     , longitude: responseJson.data.longitude
                            //     , country_code: responseJson.data.country_code
                            //     , selectedCalendarType: responseJson.data.calendar_setting
                            //     , vikram_samvat: responseJson.data.vikram_samvat
                            //     , selectedHinduYear: responseJson.data.vikram_samvat
                            //     , month_in_gujarati: responseJson.data.month_in_gujarati
                            //     , isLoading: false
                            // })
                            // setCalendarType(responseJson.data.calendar_setting)
                            setLanguageType(responseJson.data.language)
                            setGetTithi(responseJson.data.tithi)

                            // getCalenderPDF(new Date().getFullYear(),
                            //     responseJson.data.calendar_setting,
                            //     responseJson.data.vikram_samvat,
                            //     responseJson.data.longitude,
                            //     responseJson.data.latitude,
                            //     responseJson.data.country_code
                            // )
                        } else {
                            // this.setState({ isLoading: false })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    const getCalenderPDF = async (year, type, vikramsamvat, longitude, latitude, Country) => {
        // this.setState({ isLoading: true })

        const Token = await localStorage.getItem("authToken")
        const Language = await localStorage.getItem('selectedLanguage')
        // if (Language) {
        //     this.setState({ selectedLanguage: Language })
        // }

        // console.log("Form data Calendar", JSON.stringify({
        //     "flag": "multiple",
        //     "year": year.toString(),
        //     "month": month.toString(),
        //     "type": type,
        //     "latitude": this.state.latitude ? this.state.latitude : longitude,
        //     "longitude": this.state.longitude ? this.state.longitude : latitude,
        //     "country_code": this.state.country_code ? this.state.country_code : Country,
        //      "vikram_samvat": vikramsamvat.toString()
        // }))
        const Latitude = await localStorage.getItem("saralLatitude")
        const Longitude = await localStorage.getItem("saralLongitude")
        const CountryCode = await localStorage.getItem("saralCountry")
        try {
            await fetch(`${BASEURL}/getfrontcalendardatafromdaterange`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        // "year": year.toString(),
                        "year": CalendarType == "Hindu" ? "" : selectedEnglishYear,
                        "type": CalendarType,
                        "latitude": Latitude,
                        "longitude": Longitude,
                        "country_code": CountryCode,
                        // "vikram_samvat": vikramsamvat.toString()
                        "vikram_samvat": CalendarType == "Hindu" ? selectedHinduYear : ''
                    }
                    )
                })
                .then((response) => {
                    if (response) {
                        response.json().then(async (responseJson) => {
                            if (responseJson.message == "An Exception occurred while trying to authenticate the User.") {
                                localStorage.clear()
                                window.location.href = process.env.PUBLIC_URL + '/login';
                                // navigation.navigate("Splash")
                            }
                            if (responseJson.data.length > 0) {
                                // this.setState({ data: responseJson.data })
                                setData(responseJson.data)
                                await handleDownloadPdf(responseJson.data)
                            } else {
                                toastr.info("Calendar Ended We Will Update Data Soon !!")
                                // getuser()
                                // this.getCalender(this.state.currentDate.getFullYear(), this.state.currentDate.getMonth() + 1, this.state.month_in_gujarati, this.state.selectedCalendarType, this.state.vikram_samvat)
                                // this.setState({ isLoading: false })
                            }
                        });
                    } else {
                        toastr.warning("Something Went Wrong !!")
                        // this.setState({ isLoading: false })
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error !!', error);
        }

    }
    const getYear = async () => {
        // this.setState({ showLoader: true })
        const Token = await localStorage.getItem("authToken")
        const userID = await localStorage.getItem("authUser_ID")

        try {
            await fetch(`${BASEURL}/getfrontcalendargroupbyyear`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    response.json().then(async (responseJson) => {
                        if (responseJson.data) {
                            const years = responseJson?.data?.map((item) => {
                                return { label: props.t(item?.year), value: item?.year }
                                // return { label: item?.year, value: item?.year }
                            }
                            )
                            // console.log("Respionse JSON", years);
                            setyearData(years)
                            // console.log("console log data", this.state.userData)
                        } else {
                            // this.setState({ showLoader: false })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    const getHinduYear = async () => {
        // this.setState({ showLoader: true })
        const Token = await localStorage.getItem("authToken")
        const userID = await localStorage.getItem("authUser_ID")

        try {
            await fetch(`${BASEURL}/getfrontcalendargroupbyhinduyear`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    response.json().then(async (responseJson) => {
                        if (responseJson.data) {
                            const years = responseJson?.data?.map((item) => {
                                return { label: props.t(item?.year), value: item?.year }
                            }
                            )
                            // console.log("Respionse JSON", years);
                            setyearHindiData(years)
                            // console.log("console log data", this.state.userData)
                        } else {
                            // this.setState({ showLoader: false })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    // This is background color functionality
    // <div>
    //     <ul class="weekdays">
    //         ${daysOfWeek.map(day => `<li>${props.t(day)}</li>`).join('')}
    //     </ul>

    //     <ul class="days">
    //         ${Array.from({ length: firstDay }, () => `<li></li>`).join('')}
    //         ${daysArray.map(day => {
    //             // Check if there is data for this day
    //             if (day.day && day.englsih_tithi_name) {
    //                 return `
    //             <li style="background-color: 
    //                 ${day.englsih_tithi_name == "Pancham" ? '#ffc09f' :
    //                         day.englsih_tithi_name == "Chaudas" ? "#edd9c1" :
    //                             day.englsih_tithi_name == "Atham" ? "#d7e7b6" :
    //                                 day.englsih_tithi_name == "Punam" ? "#f4eeb2" :
    //                                     day.englsih_tithi_name == "Beej" ? "#d5dedb" :
    //                                         day.englsih_tithi_name == "Agiyaras" ? "#c5d4e7" :
    //                                             day.englsih_tithi_name == "Amas" ? "#f4eeb2" : '#ffffff'}">
    //                 <p style="text-align: end;">${props.t(day.paksha_type) || ''}</p>
    //                 <p class="day-number">${props.t(day.day.toString().padStart(2, '0'))}</p>
    //                 <p>${props.t(day.englsih_tithi_name) || ''}</p>
    //             </li>
    //             `;
    //             } else {
    //                 return `<li></li>`;
    //             }
    //         }).join('')}
    //         ${Array.from({ length: emptyCellsAfter }, () => `<li></li>`).join('')}
    //     </ul>
    // </div>


    const generateMonthHtml = (monthData) => {
        if (!monthData || monthData.length === 0) {
            console.error('No data available for this month');
            return '';
        }
        const newMonthData = monthData;
        const month = parseInt(monthData[0].month, 10);
        const year = parseInt(monthData[0].year, 10);
        const yearShow = CalendarType == 'Hindu' ? monthData[0]?.vikram_samvat : parseInt(monthData[0].year, 10);
        // language:"Gujarati" , "Hindi" ,"English"
        const monthName = CalendarType == 'Hindu' ? monthData[0].guj_month_english_name : monthData[0].gregorian_english_month_name;

        // const sortedMonthData = monthData.sort((a, b) => parseInt(a.day, 10) - parseInt(b.day, 10));
        // const monthName = CalendarType == 'Hindu' ? sortedMonthData[0].guj_month_english_name : sortedMonthData[0].gregorian_english_month_name;

        // Calculate the number of days in the month
        const daysInMonth = new Date(year, month, 0).getDate();
        // Calculate the starting day of the month
        const firstDay = new Date(year, month - 1, 1).getDay(); // 0 = Sunday, 1 = Monday, etc.
        // Days of the week (starting with Sunday)
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        // Generate the days array
        const daysArray = Array.from({ length: daysInMonth }, (_, i) => {
            const day = i + 1;
            // Find the full object for the current day
            const dayData = monthData.find(item => parseInt(item.day, 10) == day) || {};
            return { day, ...dayData };
        });

        // const firstDayOfWeek = daysArray?.length > 0 ? daysArray[0].day_name : 'Sunday';
        // const lastDayOfWeek = daysArray?.length > 0 ? daysArray[daysArray?.length - 1].day_name : 'Sunday';

        // const daysOfWeek2 = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        // const emptyColumnsStart = daysOfWeek2.indexOf(firstDayOfWeek);
        // // const emptyColumnsEnd = daysOfWeek.indexOf(lastDayOfWeek) == 6 ? 0 : (7 - (this.state.data.length % 7)) % 7;
        // const emptyColumnsEnd = Number((6 - daysOfWeek2.indexOf(lastDayOfWeek)).toFixed(0));

        // const emptyDataStart = Array(emptyColumnsStart).fill({});
        // const emptyDataEnd = Array(emptyColumnsEnd).fill({});

        // const fullData = [...emptyDataStart, ...daysArray, ...emptyDataEnd];


        let fullData = []

        if (CalendarType == 'Hindu') {
            const daysOfWeek2 = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            // If the first day is missing, add an empty object for that day
            const firstDayOfWeek = monthData?.length > 0 ? monthData[0].day_name : 'Sunday';
            const lastDayOfWeek = monthData?.length > 0 ? monthData[monthData?.length - 1].day_name : 'Sunday';

            const emptyColumnsStart = daysOfWeek2.indexOf(firstDayOfWeek);
            const emptyColumnsEnd = Number((6 - daysOfWeek2.indexOf(lastDayOfWeek)).toFixed(0));

            // Fill in empty days before and after
            const emptyDataStart = Array(emptyColumnsStart).fill({});
            const emptyDataEnd = Array(emptyColumnsEnd).fill({});

            fullData = [...emptyDataStart, ...monthData, ...emptyDataEnd];
            // console.log('daysArray', monthData, fullData)
        } else {
            const firstDayOfWeek = daysArray?.length > 0 ? daysArray[0].day_name : 'Sunday';
            const lastDayOfWeek = daysArray?.length > 0 ? daysArray[daysArray?.length - 1].day_name : 'Sunday';

            const daysOfWeek2 = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

            const emptyColumnsStart = daysOfWeek2.indexOf(firstDayOfWeek);
            // const emptyColumnsEnd = daysOfWeek.indexOf(lastDayOfWeek) == 6 ? 0 : (7 - (this.state.data.length % 7)) % 7;
            const emptyColumnsEnd = Number((6 - daysOfWeek2.indexOf(lastDayOfWeek)).toFixed(0));

            const emptyDataStart = Array(emptyColumnsStart).fill({});
            const emptyDataEnd = Array(emptyColumnsEnd).fill({});

            fullData = [...emptyDataStart, ...daysArray, ...emptyDataEnd];

        }

        const listItems = daysArray.map(day => {
            if (day.panchkalyanak_data?.length) {
                return day.panchkalyanak_data.map(data => {
                    const dayOfMonth = data.date_calendar?.split('-')[2]; // Extract day from date
                    return `<li style="padding: 2px;">${props.t('Day')} ${props.t(dayOfMonth)}: ${props.t(data.kalyanak_god_name)}</li>`;
                }).join('');
            }
            return '';
        }).join('');
        const jainItems = daysArray.map(day => {
            if (day.jainfestival_data?.length) {
                return day.jainfestival_data.map(data => {
                    const dayOfMonth = data.date_calendar?.split('-')[2]; // Extract day from date
                    if (selectedLanguage == "Gujarati") {
                        return `<li style="padding: 2px;">${props.t('Day')} ${props.t(dayOfMonth)}: ${data.festival_name_gujarati}</li>`;
                    } else if (selectedLanguage == "Hindi") {
                        return `<li style="padding: 2px;">${props.t('Day')} ${props.t(dayOfMonth)}: ${data.festival_name_hindi}</li>`;
                    } else {
                        return `<li style="padding: 2px;">${props.t('Day')} ${props.t(dayOfMonth)}: ${data.festival_name}</li>`;
                    }
                }).join('');
            }
            return '';
        }).join('');
        const extraNotsItems = daysArray.map(day => {
            if (day.extranotes_data?.length) {
                return day.extranotes_data.map(data => {
                    const dayOfMonth = data.date_calendar?.split('-')[2]; // Extract day from date
                    if (selectedLanguage == "Gujarati") {
                        return `<li style="padding: 2px;">${props.t('Day')} ${props.t(dayOfMonth)}: ${data.notes_gujarati}</li>`;
                    } else if (selectedLanguage == "Hindi") {
                        return `<li style="padding: 2px;">${props.t('Day')} ${props.t(dayOfMonth)}: ${data.notes_hindi}</li>`;
                    } else {
                        return `<li style="padding: 2px;">${props.t('Day')} ${props.t(dayOfMonth)}: ${data.notes}</li>`;
                    }

                }).join('');
            }
            return '';
        }).join('');
        // Calculate total cells required (empty cells + days)
        const totalCells = firstDay + daysInMonth;

        // Calculate the number of rows needed
        const rows = Math.ceil(totalCells / 7);

        // Calculate the number of empty cells to make up to 6 rows
        const totalCellsRequired = 7 * 6; // 6 rows * 7 days
        const emptyCellsAfter = totalCellsRequired - totalCells;

        return `
    <html>
    <head>
    <style>
        * { box-sizing: border-box; }
        ul { list-style-type: none; margin: 0; padding: 0; }
        body { font-family: Segoe UI, Verdana, sans-serif; margin: 0;  background: transparent; }

        .month {
            padding: 40px 10px 0px 10px;
            width: ${CalendarType == 'Hindu' && fullData.length > 49 ? "1532px" : "1490px"};
            border: 1px solid #ccc;
            min-height: 140px;
        }

        .weekdays, .days {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            margin: 0;
            padding: 0;
            
        }

        .weekdays li, .days li {
            padding: 6px;
            text-align: center;
            border: 1px solid #ccc;
            font-size: 16px;
            max-width: 130px;
        min-width: 140px;
        }

        .days {
            height: 100%;
            display: grid;
            grid-template-rows: repeat(6, 1fr); /* Force 6 rows */
        }

        .days li {
            font-size: 14px;
        }

        .days .day-number {
            font-size: 24px;
            font-weight: 900;
        }

        .days li p {
            margin: 0px 0;
        }

        .active {
            color: white !important;
        }
        .thiti {
             width:${CalendarType == 'Hindu' && fullData.length > 49 ? "1510px" : "1470px"};
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            margin: ${CalendarType == 'Hindu' && fullData.length > 49 ? "30px 10 10px 15" : "60px 10 10px 15"};
            padding: 0;
             text-align: center;
        }
        .thiti li {
            padding: 10px;
            margin-right:8px;
            margin-top:8px;
            border: 1px solid #ccc;
            font-size: 16px;
        }
        
    </style>
    </head>
    <body>
        <div style="position: fixed;    
                left: 500px;
                top: 400px;
                z-index: -10;
                opacity: 0.05;">
                <a href="https://absolutewebdevelopment.in/saralpanchang/" target="_blank">
                <img style="width: 500px; height: 180; object-fit: contain; opacity:0.1" src="${logo}" alt="Logo" title="Logo" />
            </a>
        </div>
        <div class="month">
            <ul style="display: flex; justify-content: space-between; align-items: center;">
                <li>
                    <a href="https://absolutewebdevelopment.in/saralpanchang/" target="_blank">
                        <img style="width: 220px; height: 80px; object-fit: contain;" src="${logo}" alt="Logo" title="Logo" />
                    </a>
                </li>
                <li style="color: black; align-items: center;">
                    <span style="font-size:2em">${props.t(monthName)} / </span>
                    <span style="font-size:2em">${props.t(yearShow)}</span>
                </li>
                <li style="color: black;">
                    <span style="font-size:1.5em">Email: <a href="mailto:${themeSettingData?.email}">${themeSettingData?.email}</a></span><br>
                    <span style="font-size:1.5em">Mobile: <a href="tel:${themeSettingData?.mobile}">${themeSettingData?.mobile}</a></span><br>
                </li>
            </ul>
        </div>
        <div style="display: flex;">
        <div style="">
        <div style="min-height: 100px; border: 1px solid #ccc;">
          <ul style="width:${CalendarType == 'Hindu' && fullData.length > 49 ? "270px" : "250px"};">
            <li style="color: black; border: 1px solid #ccc; text-align:center;">
                <span style="font-size:1.5em;  padding:2px; ">${props.t('Jain Festivals')}</span>
            </li>
                ${jainItems}
            </ul>
        </div>
        <div style="min-height: 100px; border: 1px solid #ccc;">
          <ul style="width:${CalendarType == 'Hindu' && fullData.length > 49 ? "270px" : "250px"};">
            <li style="color: black; border: 1px solid #ccc; text-align:center">
                <span style="font-size:1.5em;;  padding:2px; ">${props.t('Extra Notes')}</span>
            </li>
                ${extraNotsItems}
           </ul>
        </div>
        </div>
        <div>
          <ul class="weekdays">
            ${daysOfWeek.map(day => `<li>${props.t(day)}</li>`).join('')}
          </ul>
          <ul class="days">  
            ${fullData.map(day => {
            // Check if there is data for this day
            if (day.day && day.englsih_tithi_name && CalendarType == 'Gregorian') {
                return `
                        <li style="">
                            <p style="text-align: end;">${props.t(day.paksha_type) || ''}</p>
                            <p class="day-number" style= "color:
                            ${day.englsih_tithi_name == "Pancham" ? '#fc8242' :
                        day.englsih_tithi_name == "Chaudas" ? "#b8704f" :
                            day.englsih_tithi_name == "Atham" ? "#92ed64" :
                                day.englsih_tithi_name == "Punam" ? getTithi === "12" ? "#91860a" : "#000" :
                                    day.englsih_tithi_name == "Beej" ? getTithi === "12" ? "#78f0c8" : "#000" :
                                        day.englsih_tithi_name == "Agiyaras" ? getTithi === "12" ? "#87b7f5" : "#000" :
                                            day.englsih_tithi_name == "Amas" ? getTithi === "12" ? "#91860a" : "#000" : '#000'}">${props.t(day.day.toString().padStart(2, '0'))}</p>
                            <p>${props.t(day.englsih_tithi_name) || ''}</p>
                        </li>
                        `;
            } else if (day.day && day.englsih_tithi_name && CalendarType == 'Hindu') {
                return `
                        <li style="">
                            <p style="text-align: end; color:"#000;">${props.t(day.paksha_type) || ''}</p>
                           <p class="day-number" style= "color:
                            ${day.englsih_tithi_name == "Pancham" ? '#fc8242' :
                        day.englsih_tithi_name == "Chaudas" ? "#b8704f" :
                            day.englsih_tithi_name == "Atham" ? "#92ed64" :
                                day.englsih_tithi_name == "Punam" ? getTithi === "12" ? "#91860a" : "#000" :
                                    day.englsih_tithi_name == "Beej" ? getTithi === "12" ? "#78f0c8" : "#000" :
                                        day.englsih_tithi_name == "Agiyaras" ? getTithi === "12" ? "#87b7f5" : "#000" :
                                            day.englsih_tithi_name == "Amas" ? getTithi === "12" ? "#91860a" : "#000" : '#000'}">${props.t(day.tithi.toString().padStart(2, '0'))}</p>
                            <p style="color:"#000;">${props.t(day.day?.toString()) + "/" + props.t(day.month) || ''}</p>
                        </li>
                        `;
            } else {
                return `<li></li>`;
            }
        }).join('')}
        </ul>
        </div>
        <div style="border: 1px solid #ccc;">
        <ul style="width:${CalendarType == 'Hindu' && fullData.length > 49 ? "270px" : "250px"};">  
            <li style="color: black; border: 1px solid #ccc; text-align:center;">
                <span style="font-size:1.5em;  padding:2px; ">${props.t('Panch Kalyanak')}</span>
            </li>
                ${listItems}
        </ul>
        </div>
        </div>
        <div styel="display: flex;margin: 20px 10px 10px 10px;">
        <div style="">
            <ul class="thiti">
                <li style="background: #ffc09f;">
                <span style="font-size:1.5em;  padding:2px; ">${props.t('Pancham')}</span>
                </li>
                <li style="background: #edd9c1;">
                 <span style="font-size:1.5em;  padding:2px; ">${props.t('Chaudas')}</span>
                </li>
                 <li style="background: #d7e7b6;">
                <span style="font-size:1.5em;  padding:2px; ">${props.t('Atham')}</span>
                </li>
                 ${getTithi === "12" ? `
                <li style="background: #d5dedb;">
                    <span style="font-size:1.5em; padding:2px;">${props.t('Beej')}</span>
                </li>
                 <li style="background: #c5d4e7;">
                    <span style="font-size:1.5em;  padding:2px; ">${props.t('Agiyaras')}</span>
                </li>
                 <li style="background: #f4eeb2;">
                    <span style="font-size:1.5em;  padding:2px; ">${props.t('Punam')}/${props.t('Amas')}</span>
                 </li>
                ` : ''}
            </ul>
        </div>
        </div>
    </body>
    </html>
    `;
    };
    const generatePachkaanHtmlMorning = (pachkhanData) => {
        if (!pachkhanData || pachkhanData.length === 0) {
            console.error('No pachkhan data available');
            return '';
        }
        return `
            <html>
            <head>
                <style>
                    .page-break {
                        page-break-before: always;
                    }
                </style>
            </head>
            <body>
            <div style="position: fixed;
                left: 500px;
                top: 400px;
                z-index: -10;
                opacity: 0.05;">
                <a href="https://absolutewebdevelopment.in/saralpanchang/" target="_blank">
                <img style="width: 500px; height: 180; object-fit: contain; opacity:0.1" src="${logo}" alt="Logo" title="Logo" />
            </a>
        </div>
            <div style="padding: 20px;margin-bottom: 20px;width :1450px;">
            <h2 style="text-align: center;">${props.t('Pachkhan')} / ${props.t('Morning')}</h2>
            ${pachkhanData.map((item, index) => {
            if (selectedLanguage == "Gujarati") {
                return `
                        <h3>${item.name_gujarati}</h3>
                        <p style="font-size: 18px; color: #000;">${item.pachakhan_details.details_gujarati}</p>
                        ${index < pachkhanData.length - 1 ? '<div class="page-break"></div>' : ''}
                        `;
            } else if (selectedLanguage == "Hindi") {
                return `
                        <h3>${item.name_hindi}</h3>
                        <p style="font-size: 18px; color: #000;">${item.pachakhan_details.details_hindi}</p>
                        ${index < pachkhanData.length - 1 ? '<div class="page-break"></div>' : ''}
                        `;
            } else {
                return `
                        <h3>${item.name_english}</h3>
                        <p style="font-size: 18px; color: #000;">${item.pachakhan_details.details_english}</p>
                        ${index < pachkhanData.length - 1 ? '<div class="page-break"></div>' : ''}
                        `;
            }
        }).join('')}
        </div>
        </body>
        </html>`;
    };
    const generatePachkaanHtmlEvning = (pachkhanDataEvening) => {
        if (!pachkhanDataEvening || pachkhanDataEvening.length === 0) {
            console.error('No pachkhan data available');
            return '';
        }
        return `
                <html>
                <head>
                    <style>
                        .page-break {
                            page-break-before: always;
                        }
                    </style>
                </head>
                <body>
                <div style="position: fixed;
                    left: 500px;
                    top: 400px;
                    z-index: -10;
                    opacity: 0.05;">
                    <a href="https://absolutewebdevelopment.in/saralpanchang/" target="_blank">
                    <img style="width: 500px; height: 180; object-fit: contain; opacity:0.1" src="${logo}" alt="Logo" title="Logo" />
                </a>
            </div>
                <div style="padding: 20px;margin-bottom: 20px;width :1450px;">
                <h2 style="text-align: center;">${props.t('Pachkhan')} / ${props.t('Evening')}</h2>
            ${pachkhanDataEvening.map((item, index) => {
            if (selectedLanguage == "Gujarati") {
                return `
                                <h3>${item.name_gujarati}</h3>
                                <p style="font-size: 18px; color: #000;">${item.pachakhan_details.details_gujarati}</p>
                                ${index < pachkhanDataEvening.length - 1 ? '<div class="page-break"></div>' : ''}
                            `;
            } else if (selectedLanguage == "Hindi") {
                return `
                                <h3>${item.name_hindi}</h3>
                                <p style="font-size: 18px; color: #000;">${item.pachakhan_details.details_hindi}</p>
                                ${index < pachkhanDataEvening.length - 1 ? '<div class="page-break"></div>' : ''}
                            `;
            } else {
                return `
                                <h3>${item.name_english}</h3>
                                <p style="font-size: 18px; color: #000;">${item.pachakhan_details.details_english}</p>
                                ${index < pachkhanDataEvening.length - 1 ? '<div class="page-break"></div>' : ''}
                            `;
            }
        }).join('')}
                            </div>
                </body>
                </html>`;
    };

    // 16th Aug
    const handleDownloadPdf = (dataProcess) => {
        setIsLoading(true)
        const pdf = new jsPDF('l', 'mm', 'a4'); // Landscape orientation
        const months = Array.from({ length: 12 }, (_, i) => i + 1);
        // Watermark function
        // const addWatermark = (pdf, text = "Saral Jain Panchang") => {
        //     const pageWidth = pdf.internal.pageSize.getWidth();
        //     const pageHeight = pdf.internal.pageSize.getHeight();
        //     const fontSize = 16; // Font size for watermark
        //     const opacity = 0.1; // Transparency for watermark
        //     const angle = 45; // Angle for rotation
        //     const spacing = 45; // Spacing between watermarks
        //     const countX = Math.ceil(pageWidth / spacing); // Number of columns
        //     const countY = Math.ceil(pageHeight / spacing); // Number of rows

        //     pdf.setTextColor(230, 230, 230); // Lighter color for background
        //     pdf.setFontSize(fontSize); // Set font size
        //     pdf.setFont('helvetica', 'italic');

        //     for (let x = 0; x < countX; x++) {
        //         for (let y = 0; y < countY; y++) {
        //             const posX = x * spacing;
        //             const posY = y * spacing;

        //             if (posX < pageWidth && posY < pageHeight) {
        //                 pdf.text(text, posX, posY, {
        //                     angle: angle,
        //                     align: 'center',
        //                     opacity: opacity,
        //                     zIndx: -1,
        //                 });
        //             }
        //         }
        //     }
        // };

        const addWatermark = (pdf) => {
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const watermarkWidth = 50; // Adjust width as needed
            const watermarkHeight = 20; // Adjust height as needed

            // Calculate center position
            const centerX = pageWidth / 2;
            const centerY = pageHeight / 2;

            // Set opacity for the watermark
            pdf.setGState(new pdf.GState({ opacity: 0.1 }));

            // Add the base64 image as a watermark, rotated by 90 degrees
            pdf.addImage(
                logo,
                'PNG',
                centerX - watermarkHeight / 2, // Adjust X position
                centerY - watermarkWidth / 2,  // Adjust Y position
                watermarkWidth,
                watermarkHeight,
                undefined,
            );
        };

        const addIntroductionPage = () => {
            const pageWidth = pdf.internal.pageSize.getWidth();

            // Set the font and color for the title
            pdf.setFontSize(30);
            pdf.setTextColor(0, 0, 0);
            pdf.setFont('helvetica', 'bold');

            // Add logo image and get the position for text
            const logoWidth = 100;
            const logoHeight = 40;
            const logoX = (pageWidth - logoWidth) / 2; // Center the logo horizontally
            const logoY = 40;

            // Add the logo image
            pdf.addImage(logoFirstPage, 'JPEG', logoX, logoY, logoWidth, logoHeight);

            // Add a link to the logo
            pdf.link(logoX, logoY, logoWidth, logoHeight, { url: 'https://saraljainpanchang.com/' });

            // Add title below the logo
            const titleY = logoY + logoHeight + 20; // Adjust the spacing as needed
            pdf.text("Saral Jain Panchang", pageWidth / 2, titleY, { align: 'center' });

            // Set the font size for the address and other info
            pdf.setFontSize(14);
            const infoYStart = titleY + 20; // Start 20 units below the title

            // Add address information with hyperlinks
            const addressText = [
                `Email: ${themeSettingData?.email}`,
                `Mobile: ${themeSettingData?.mobile}`,
                `Address: ${themeSettingData?.address}`
            ];

            // Calculate the starting Y position for the info text to be centered
            const infoTextHeight = addressText.length * 10; // Assuming each line is 10 units high
            const infoY = infoYStart + (infoTextHeight / 2);

            // addressText.forEach((line, index) => {
            //     pdf.text(line, pageWidth / 2, infoYStart + (index * 10), { align: 'center' });
            // });

            // // Add hyperlinks for email and mobile
            // if (themeSettingData?.email) {
            //     pdf.link(pageWidth / 2 - 30, infoYStart + 0, 60, 10, { url: `mailto:${themeSettingData.email}` });
            // }

            // if (themeSettingData?.mobile) {
            //     pdf.link(pageWidth / 2 - 30, infoYStart + 10, 60, 10, { url: `tel:${themeSettingData.mobile}` });
            // }
            // Add hyperlinks and set blue color for email and mobile
            addressText.forEach((line, index) => {
                const yPos = infoYStart + (index * 10);

                if (line.includes('Email')) {
                    pdf.setTextColor(0, 0, 255); // Set color to blue for email
                    pdf.textWithLink(line, pageWidth / 2, yPos, { align: 'center', url: `mailto:${themeSettingData.email}` });
                } else if (line.includes('Mobile')) {
                    pdf.setTextColor(0, 0, 255); // Set color to blue for mobile
                    pdf.textWithLink(line, pageWidth / 2, yPos, { align: 'center', url: `tel:${themeSettingData.mobile}` });
                } else {
                    pdf.setTextColor(0, 0, 0); // Default black color for address
                    pdf.text(line, pageWidth / 2, yPos, { align: 'center' });
                }
            });

            // Add a new page for the next section
            pdf.addPage();
        };

        const processMonth = (month, index) => {
            return new Promise((resolve, reject) => {
                // Filter data for the current month
                const monthData = CalendarType == 'Hindu' ? dataProcess.filter(item => parseInt(item.month_in_gujarati, 10) === month) : dataProcess.filter(item => parseInt(item.month, 10) === month);
                var monthHtml = ""
                if (index <= 11) {
                    monthHtml = generateMonthHtml(monthData)
                } else if (index == 12) {
                    monthHtml = generatePachkaanHtmlMorning(pachkhanData)
                } else {
                    monthHtml = generatePachkaanHtmlEvning(pachkhanDataEvening)
                    setIsLoading(false)
                }

                // Create an iframe to render HTML content
                const iframe = document.createElement('iframe');
                iframe.style.position = 'absolute';
                iframe.style.top = '-100000px';
                document.body.appendChild(iframe);
                const doc = iframe.contentWindow.document;
                doc.open();
                doc.write(monthHtml);
                doc.close();

                // Wait for the content to fully render
                setTimeout(() => {
                    // html2canvas(iframe.contentWindow.document.body, { scale: 2 })
                    //     .then((canvas) => {
                    //         const imgData = canvas.toDataURL('image/jpeg', 1.0);
                    //         const pdfWidth = pdf.internal.pageSize.getWidth();
                    //         const pdfHeight = pdf.internal.pageSize.getHeight();
                    //         const canvasWidth = canvas.width;
                    //         const canvasHeight = canvas.height;
                    //         const ratio = Math.min(pdfWidth / canvasWidth, pdfHeight / canvasHeight);

                    //         if (index > 0) {
                    //             pdf.addPage(); // Add a new page for each month
                    //         }

                    //         // Add the watermark to the current page (background)
                    //         addWatermark(pdf);

                    //         // Then add the calendar image on top of the watermark
                    //         pdf.addImage(imgData, 'JPEG', 0, 0, canvasWidth * ratio, canvasHeight * ratio, '', 'FAST')
                    //         // Clean up
                    //         document.body.removeChild(iframe);
                    //         resolve();
                    //     })
                    //     .catch((error) => {
                    //         console.error('Error generating PDF:', error);
                    //         document.body.removeChild(iframe);
                    //         reject(error);
                    //     });

                    html2canvas(iframe.contentWindow.document.body, {
                        scale: 2,
                        backgroundColor: null, // This ensures that the background remains transparent
                        logging: false,
                    }).then((canvas) => {
                        const imgData = canvas.toDataURL('image/png'); // Use PNG to preserve transparency
                        const pdfWidth = pdf.internal.pageSize.getWidth();
                        const pdfHeight = pdf.internal.pageSize.getHeight();
                        const canvasWidth = canvas.width;
                        const canvasHeight = canvas.height;
                        const ratio = Math.min(pdfWidth / canvasWidth, pdfHeight / canvasHeight);

                        if (index > 0) {
                            pdf.addPage(); // Add a new page for each month
                        }

                        // Add the watermark to the current page (background)
                        // addWatermark(pdf);

                        // Then add the calendar image on top of the watermark
                        pdf.addImage(imgData, 'PNG', 0, 0, canvasWidth * ratio, canvasHeight * ratio, '', 'FAST');
                        // Clean up
                        document.body.removeChild(iframe);
                        resolve();
                    }).catch((error) => {
                        console.error('Error generating PDF:', error);
                        document.body.removeChild(iframe);
                        reject(error);
                    });

                },); // Adjust this timeout if needed
            });
        };

        const processAllMonths = async () => {
            addIntroductionPage(); // Add the introduction page
            const item = isChecked ? 14 : 12
            for (let i = 0; i < item; i++) {
                await processMonth(months[i], i);
                i == item - 1 ? setIsLoading(false) : setIsLoading(true)
            }
            pdf.save(`Saral Jain Panchang_${new Date().getFullYear()}.pdf`);
        };
        processAllMonths();
    };
    const optionCalendar = [
        { label: "English (Gregorian)", value: "Gregorian" },
        { label: "Jain (Hindu)", value: "Hindu" },
    ];
    const optionLanguage = [
        { label: "Hindi", value: "Hindi" },
        { label: "English", value: "English" },
        { label: "Gujarati", value: "Gujarati" },
    ];
    console.log('development', process.env.NODE_ENV)
    return (
        <div>
            {/* <button onClick={handleDownloadPdf}>Download Calendar PDF</button> */}
            <Loader isLoading={loading} />
            <div className="ast_service_wrapper ast_toppadder70 ast_bottompadder40 bg-light" style={{ zIndex: 10, position: 'relative' }}>
                <div className="container">
                    <div style={{ borderRadius: 10, backgroundColor: "#fff", padding: 15, marginTop: 10, boxShadow: '1px 2px 9px #d3d3d3', }}>
                        <Row style={{ alignItems: "center" }}>
                            <Col lg="3">
                                <div className="mb-3">
                                    <Label className="form-label"> Calendar Type</Label>
                                    <Select
                                        value={optionCalendar.find(option => option.value === CalendarType)}
                                        onChange={(e) => setCalendarType(e.value)}
                                        options={optionCalendar}
                                        classNamePrefix="select2-selection"
                                    />
                                </div>
                            </Col>
                            <Col lg="3">
                                <div className="mb-3">
                                    <Label className="form-label">Select Year</Label>
                                    {
                                        CalendarType == "Hindu" ? (
                                            <Select
                                                value={yearHindiData.find(option => option.value == selectedHinduYear)}
                                                onChange={(e) => {
                                                    setSelectedHinduYear(e.value)
                                                }}
                                                options={yearHindiData}
                                                styles={{ zindex: 99 }}
                                                classNamePrefix="select2-selection"
                                            />
                                        ) : (
                                            <Select
                                                value={yearData.find(option => option.value == selectedEnglishYear)}
                                                onChange={(e) => {
                                                    setSelectedEnglishYear(e.value)
                                                }}
                                                options={yearData}
                                                styles={{ zindex: 99 }}
                                                classNamePrefix="select2-selection"
                                            />
                                        )
                                    }
                                </div>
                            </Col>

                            <Col lg={3}>
                                <div className="mb-3">
                                    <Label className="form-label"> Languages</Label>
                                    <Select
                                        value={optionLanguage.find(option => option.value == selectedLanguage)}
                                        onChange={(e) => {
                                            setSelectedLanguage(e.value)
                                            changeLanguageAction(e.value)
                                        }}
                                        options={optionLanguage}
                                        classNamePrefix="select2-selection"
                                    />
                                </div>
                            </Col>
                            <Col lg="1">
                                <AvForm >
                                    <FormGroup className="mt-4">
                                        <AvGroup check>
                                            <AvInput
                                                type="checkbox"
                                                name="Pachkhan"
                                                customInput
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                            />
                                            <Label check for="checkbox">
                                                {" "}
                                                Pachkhan
                                            </Label>
                                        </AvGroup>
                                    </FormGroup>
                                </AvForm>
                            </Col>
                            <Col lg="2">
                                <div>
                                    <button

                                        onClick={() => getCalenderPDF()} className="ast_btn " style={{
                                            backgroundColor: "#3b3483",
                                            borderRadius: 10,
                                            color: "#fff"
                                        }}
                                        disabled={loading ? true : false}
                                    >{loading ? 'Generating PDF...' : 'Download PDF'}</button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div></div>
        </div>
    );
};
export default withNamespaces()(GenerateCalendarPDF);

