import React, { Component } from "react";
import { Col, Label, Row, Table } from "reactstrap";
import "flatpickr/dist/themes/material_blue.css"
import "../../../Components/css/navkarshi.css"
import Select from "react-select";
import { withNamespaces } from "react-i18next";
import getCurrentDate, { BASEURL } from "../../../Global";
import Loader from "../Loader";
import i18n from "../../../i18n";

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const date = new Date();
const options = { month: 'long' };
// const monthName = date.toLocaleString('en-US', options);
const monthName = date.getMonth() + 1;
const yearName = date.getFullYear();

class JainFestival extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedEnglishMonth: monthName,
            selectedEnglishYear: yearName,
            yearData: [],
            // selectedEnglishMonth: "September",
            extranotesData: [],
            currentPage: 1,
            totalPages: 1,
            isLoading: false

        };
        this.interval = null;
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        const panchkalyanakLocale = JSON.parse(localStorage.getItem('localData'))
         if (this.state.selectedEnglishYear == yearName && panchkalyanakLocale) {
            const panchkalyanakLocale = JSON.parse(localStorage.getItem('localData'))
            const filteredData = await panchkalyanakLocale?.jainfestival_data.filter(item => {
                const [itemYear, itemMonth] = item.date_calendar.split('-');
                return itemYear == this.state.selectedEnglishYear && parseInt(itemMonth, 10) == parseInt(this.state.selectedEnglishMonth, 10);
            });
            console.log('jainfestival_data', panchkalyanakLocale?.jainfestival_data, filteredData)
            this.setState({ extranotesData: filteredData })

        } else {
        this.getJainfestival(this.state.currentPage, this.state.selectedEnglishMonth , this.state.selectedEnglishYear)
        }
        this.getYear()
        const Language = await localStorage.getItem('selectedLanguage')
        if (Language) {
            this.setState({ selectedLanguage: Language })
            this.changeLanguageAction(Language)
        } else {
            this.setState({ selectedLanguage: "English" })
            this.changeLanguageAction("English")

        }
        this.interval = setInterval(this.myBackgroundFunction, 30000); // 30,000 milliseconds = 30 seconds
    }
    async getJainfestival(page, month, year) {
        this.setState({ showLoader: true, selectedEnglishMonth: month, selectedEnglishYear: year })
        const Token = await localStorage.getItem("authToken")

        // console.log("Form data", JSON.stringify({
        //     "month_in_english": month
        // }))


        try {
            await fetch(`${BASEURL}/getfrontjainfestivals?page=${page}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "month_in_english": month,
                        "year": year
                    })
                })
                .then((response) => {
                    response.json().then((responseJson) => {
                        // console.log("Respionse JSON", responseJson);
                        if (responseJson.message == "An Exception occurred while trying to authenticate the User.") {
                            localStorage.clear()
                            window.location.href = process.env.PUBLIC_URL + '/login';
                        }
                        if (responseJson.data) {
                            this.setState({
                                extranotesData: responseJson.data,
                                showLoader: false,
                                selectedEnglishMonth: month,
                                // currentPage: responseJson.meta.pagination.current_page,
                                // totalPages: responseJson.meta.pagination.total_pages,
                            })
                        } else {
                            toastr.warning("Something Went Wrong !!")

                            this.setState({ showLoader: false, extranotesData: null })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    async getYear() {
        // this.setState({ showLoader: true })
        const Token = await localStorage.getItem("authToken")
        const userID = await localStorage.getItem("authUser_ID")

        try {
            await fetch(`${BASEURL}/getfrontcalendargroupbyyear`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    response.json().then(async (responseJson) => {
                        if (responseJson.data) {
                            const years = responseJson?.data?.map((item) =>{
                                return { label: this.props.t(item?.year), value: item?.year }
                            }
                            )
                            // console.log("Respionse JSON", years);
                            this.setState({yearData: years })
                            // console.log("console log data", this.state.userData)
                        } else {
                            this.setState({ showLoader: false })
                        }
                    });

                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    myBackgroundFunction = async () => {
        const Language = await localStorage.getItem('selectedLanguage')
        if (Language) {
            this.setState({ selectedLanguage: Language })
            this.changeLanguageAction(Language)
        } else {
            this.setState({ selectedLanguage: "English" })
            this.changeLanguageAction("English")
        }
    }

    changeLanguageAction = async (lng) => {
        if (lng === "English") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'English')
            await i18n.changeLanguage('eng');
        } else if (lng === "Gujarati") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'Gujarati')
            await i18n.changeLanguage('guj');
        } else if (lng === "Hindi") {
            await this.setState({ selectedLanguage: lng, })
            await localStorage.setItem('selectedLanguage', 'Hindi')
            await i18n.changeLanguage('hin');
        } else {
            await this.setState({ selectedLanguage: 'eng', })
            await localStorage.setItem('selectedLanguage', 'English')
            await i18n.changeLanguage('eng');
        }
    }

    render() {
        const optionMonthEnglish = [
            { label: this.props.t("January"), value: 1 },
            { label: this.props.t("February"), value: 2 },
            { label: this.props.t("March"), value: 3 },
            { label: this.props.t("April"), value: 4 },
            { label: this.props.t("May"), value: 5 },
            { label: this.props.t("June"), value: 6 },
            { label: this.props.t("July"), value: 7 },
            { label: this.props.t("August"), value: 8 },
            { label: this.props.t("September"), value: 9 },
            { label: this.props.t("October"), value: 10 },
            { label: this.props.t("November"), value: 11 },
            { label: this.props.t("December"), value: 12 }
        ]
        return (
            <React.Fragment>

                <Loader isLoading={this.state.showLoader} />
                <div className="ast_service_wrapper ast_toppadder70 ast_bottompadder40 bg-light" style={{ zIndex: 10, position: 'relative' }}>
                    <div className="container">
                        <div className="row ast_bottompadder30" style={{ justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap' }}>
                            <Col lg="6">
                                <div className="mb-3">
                                    <Label className="form-label"> Select Month</Label>
                                    <Select
                                        value={optionMonthEnglish.find(option => option.value === this.state.selectedEnglishMonth)}
                                        onChange={(e) => {
                                            this.setState({ selectedEnglishMonth: e.value })
                                            if (this.state.selectedEnglishYear == yearName) {
                                                const panchkalyanakLocale = JSON.parse(localStorage.getItem('localData'))
                                                const filteredData = panchkalyanakLocale?.jainfestival_data.filter(item => {
                                                    const [itemYear, itemMonth] = item.date_calendar.split('-');
                                                    return itemYear == this.state.selectedEnglishYear && parseInt(itemMonth, 10) == parseInt(e.value, 10);
                                                });
                                                this.setState({ extranotesData: filteredData })
                                                this.setState({ selectedEnglishMonth: e.value })

                                            } else {
                                                this.getJainfestival(this.state.currentPage, e.value, this.state.selectedEnglishYear)
                                                this.setState({ selectedEnglishMonth: e.value })
                                            }
                                        }}
                                        options={optionMonthEnglish}
                                        styles={{ zindex: 99 }}
                                        classNamePrefix="select2-selection"
                                    />
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="mb-3">
                                    <Label className="form-label"> Select Year</Label>
                                    <Select
                                        value={this.state.yearData.find(option => option.value == this.state.selectedEnglishYear)}
                                        onChange={(e) => {
                                            this.setState({ selectedEnglishYear: e.value })
                                            if (e.value == yearName) {
                                                const panchkalyanakLocale = JSON.parse(localStorage.getItem('localData'))
                                                const filteredData = panchkalyanakLocale?.jainfestival_data.filter(item => {
                                                    const [itemYear, itemMonth] = item.date_calendar.split('-');
                                                    return itemYear == parseInt(e.value) && parseInt(itemMonth, 10) == parseInt(this.state.selectedEnglishMonth, 10);
                                                });
                                                this.setState({ extranotesData: filteredData })
                                                this.setState({ selectedEnglishYear: e.value })
                                            }
                                            else {
                                                this.getJainfestival(this.state.currentPage, this.state.selectedEnglishMonth, e.value)
                                                this.setState({ selectedEnglishYear: e.value })
                                            }
                                        }}
                                        options={this.state.yearData}
                                        styles={{ zindex: 99 }}
                                        classNamePrefix="select2-selection"
                                    />
                                </div>
                            </Col>
                            {/* <Col lg="2">
                                <div style={{ textAlign: 'end', }}>
                                    <a  onClick={() => {
                                         if (this.state.selectedEnglishYear == yearName) {
                                                const panchkalyanakLocale = JSON.parse(localStorage.getItem('localData'))
                                                const filteredData = panchkalyanakLocale?.jainfestival_data.filter(item => {
                                                    const [itemYear, itemMonth] = item.date_calendar.split('-');
                                                    return itemYear == this.state.selectedEnglishYear && parseInt(itemMonth, 10) == parseInt(this.state.selectedEnglishMonth, 10);
                                                });
                                                this.setState({ extranotesData: filteredData })
                                            } else {
                                                this.getJainfestival(this.state.currentPage, this.state.selectedEnglishMonth, this.state.selectedEnglishYear)
                                            }
                                    }} className="ast_btn mt-2" style={{height:'39px' , lineHeight:'36px'}}>Submit</a>
                                </div>
                            </Col> */}
                        </div>
                        <div style={{ borderRadius: 10, backgroundColor: "#fff", padding: 15, marginTop: 10, boxShadow: '1px 2px 9px #d3d3d3', }}>
                            <Row>
                                <Col lg={12}>
                                    <div className="table-responsive">
                                        <Table className="table-centered mb-0 table-nowrap">
                                            <thead className="" style={{ backgroundColor: "#3b3483", color: "#fff" }}>
                                                <tr>
                                                    {/* <th style={{ width: "120px" }}>{this.props.t(`Profile Image`)}</th> */}
                                                    <th>{this.props.t(`Date`)}</th>
                                                    <th>{this.props.t(`Festival Name`)}</th>
                                                    <th>{this.props.t(`Festival Info`)}</th>
                                                </tr>
                                            </thead>
                                            {this.state.extranotesData?.length > 0 ?
                                                <tbody>
                                                    {this.state.extranotesData.map((item, index) => {
                                                        let date = getCurrentDate(item.date_calendar)
                                                        let day = this.props.t(date?.split("/")[0])
                                                        let month = this.props.t(date?.split("/")[1])
                                                        let year = this.props.t(date?.split("/")[2])
                                                        return (
                                                            <tr key={item.id}>
                                                                <td>
                                                                    <p className="mb-0">
                                                                        {/* {this.props.t(`Date`)} :{" "} */}
                                                                        <span className="fw-medium fw-bold">
                                                                            {this.state.selectedLanguage === "English" && day + "/" + month + "/" + year}
                                                                            {this.state.selectedLanguage === "Gujarati" && day + "/" + month + "/" + year}
                                                                            {this.state.selectedLanguage === "Hindi" && day + "/" + month + "/" + year}
                                                                        </span>
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    <p className="mb-0">
                                                                        <span className="fw-medium fw-bold">
                                                                            {/* {this.props.t(`Festival Name`)} :{" "} */}
                                                                            {this.state.selectedLanguage === "English" && item.festival_name}
                                                                            {this.state.selectedLanguage === "Gujarati" && item.festival_name_gujarati}
                                                                            {this.state.selectedLanguage === "Hindi" && item.festival_name_hindi}
                                                                        </span>
                                                                    </p>
                                                                </td>
                                                                <td>
                                                                    <p className="mb-0">
                                                                        {/* {this.props.t(`Festival Info`)} :{" "} */}
                                                                        <span className="fw-medium fw-bold">
                                                                            {this.state.selectedLanguage === "English" && item.festival_info}
                                                                            {this.state.selectedLanguage === "Gujarati" && item.festival_info_gujarati}
                                                                            {this.state.selectedLanguage === "Hindi" && item.festival_info_hindi}
                                                                        </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                                :
                                                <tr>
                                                    <td colSpan={3}>
                                                        <div style={{ justifyContent: 'center', width: "100%", height: 150, textAlign: 'center', display: 'flex' }}>
                                                            <h2 style={{ margin: 'auto' }}>{this.props.t(`No Data Found From Jain Festival!`)}</h2>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(JainFestival);