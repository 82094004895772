import React, { useEffect, } from "react";
import { BrowserRouter as Router, Route, Routes, } from "react-router-dom";
import PrivateRoute from "./Routes/Protected/ProtectedRoute";
import "../src/css/animate.css"
import "../src/css/bootstrap.css"
import "../src/css/fonts.css"
import "../src/css/magnific-popup.css"
import "../src/css/owl.carousel.css"
import "../src/css/owl.theme.default.css"
import "../src/css/style.css"
import "../src/css/font-awesome.css"
import Index from "./Components/Index";
import ContactUS from "./Components/ContactUs/Contact";
import AboutUS from "./Components/About/AboutUS";
import TermsofUse from "./Components/TermsOfUse/Terms";
import Privacyofuse from "./Components/Privacy/Privacy";
import Login from "./Components/Auth/Login";
import Home from "./Components/HomeIndex/Home";
import NavkarshiPage from "./Components/Navkarshi/Navkarshi";
import ChoghadiyaPage from "./Components/Choghadiya/Choghadiya";
import SuryarashiPage from "./Components/Rashi/Suryarashi";
import ChandrarashiPage from "./Components/Rashi/Chandrarashi";
import HoraPage from "./Components/Hora/Hora";
import Register from "./Components/Auth/Register";
import PanchkalyanakPage from "./Components/Panchkalyanak/Panchkalyanak";
import ExtranotesPage from "./Components/Extranotes/Extranotes";
import JainfestivalPage from "./Components/Jainfestival/Jainfestival";
import PachkhanPage from "./Components/Pachkhan/Pachkhan";
import ProfilePage from "./Components/Profile/Profile";
import PreferencePage from "./Components/Profile/Preference";
import FeaturesPage from "./Components/Features/FeaturesPage";
import SuryanakshtraPage from "./Components/Nakshtra/Suryanakshtra";
import ChandranakshtraPage from "./Components/Nakshtra/Chandranakshtra";
import PreferenceNotificationPage from "./Components/Profile/PreferenceNotificationPage";
import ClanderPDFpage from "./Pages/Pdf/ClanderPDFpage";

function App() {
  useEffect(() => {

  }, [])
  // process.env.NODE_ENV === 'production'
  // if (process.env.NODE_ENV === 'production') {
  //   //  console.log = () => {};
  //   ['log', 'warn', 'error', 'info', 'debug'].forEach((method) => {
  //     console[method] = () => { };
  //   });
  // }
  return (
    <React.Fragment>
      <Router>
        <Routes>
          {/* <Route path={process.env.PUBLIC_URL + "/normal"} element={<Normal />} /> */}
          <Route path={process.env.PUBLIC_URL + "/"} element={<Index />} />
          <Route path={process.env.PUBLIC_URL + "/contactus"} element={<ContactUS />} />
          <Route path={process.env.PUBLIC_URL + "/about"} element={<AboutUS />} />
          <Route path={process.env.PUBLIC_URL + "/term-condition"} element={<TermsofUse />} />
          <Route path={process.env.PUBLIC_URL + "/privacy-policy"} element={<Privacyofuse />} />
          <Route path={process.env.PUBLIC_URL + "/features"} element={<FeaturesPage />} />
          <Route path={process.env.PUBLIC_URL + "/login"} element={<Login />} />
          <Route path={process.env.PUBLIC_URL + "/signup"} element={<Register />} />
          <Route element={<PrivateRoute />}>
            <Route path={process.env.PUBLIC_URL + "/user-dashboard"} element={<Home />} />
            <Route path={process.env.PUBLIC_URL + "/navkarshi"} element={<NavkarshiPage />} />
            <Route path={process.env.PUBLIC_URL + "/choghadiya"} element={<ChoghadiyaPage />} />
            <Route path={process.env.PUBLIC_URL + "/suryarashi"} element={<SuryarashiPage />} />
            <Route path={process.env.PUBLIC_URL + "/chandrarashi"} element={<ChandrarashiPage />} />
            <Route path={process.env.PUBLIC_URL + "/suryanakshtra"} element={<SuryanakshtraPage />} />
            <Route path={process.env.PUBLIC_URL + "/chandranakshtra"} element={<ChandranakshtraPage />} />
            <Route path={process.env.PUBLIC_URL + "/hora"} element={<HoraPage />} />
            <Route path={process.env.PUBLIC_URL + "/panchkalyanak"} element={<PanchkalyanakPage />} />
            <Route path={process.env.PUBLIC_URL + "/extranotes"} element={<ExtranotesPage />} />
            <Route path={process.env.PUBLIC_URL + "/jainfestival"} element={<JainfestivalPage />} />
            <Route path={process.env.PUBLIC_URL + "/pachkhan"} element={<PachkhanPage />} />
            <Route path={process.env.PUBLIC_URL + "/user-profile"} element={<ProfilePage />} />
            <Route path={process.env.PUBLIC_URL + "/user-preference"} element={<PreferencePage />} />
            <Route path={process.env.PUBLIC_URL + "/user-notification"} element={<PreferenceNotificationPage />} />
            <Route path={process.env.PUBLIC_URL + "/calender-pdf"} element={<ClanderPDFpage />} />
          </Route>
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
